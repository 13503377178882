// Modal.js
import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const MenuModal = ({ open, onClose, categories, activeCategory, setActiveCategory, itemCounts, qrsettigns, getContrastingTextColor }) => {
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const modalClassName = open ? 'menuModalContainer open' : 'menuModalContainer';
    const modalContentClassName = open ? 'menuModalContent open' : 'menuModalContent';

    return (
        <Box className={modalClassName}>
            <Box className={modalContentClassName} style={{backgroundColor: qrsettigns?.background_color}}>
                <Box p={2} className="modalButtonList">
                    {categories.map((category, index) => (
                        <Button
                            variant='text'
                            key={category.category_id}
                            style={{backgroundColor: activeCategory === category.category_id ? qrsettigns['primary_color'] : 'initial', color: activeCategory === category.category_id ? getContrastingTextColor((qrsettigns['primary_color'] !== '') ?qrsettigns['primary_color']:'#ffffff') : getContrastingTextColor((qrsettigns?.background_color !== '') ?qrsettigns?.background_color:'#ffffff')}}
                            className={`menuButton ${activeCategory === category.category_id ? 'active' : ''}`}
                            onClick={() => {
                                onClose();
                                setActiveCategory(category.category_id);
                                const categoryElement = document.getElementById(`category-${category.category_id}`);
                                if (categoryElement) {
                                    categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                }
                            }}
                        >
                            <Typography>{category.category_name}</Typography> <Typography>{itemCounts[index]}</Typography>
                        </Button>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default MenuModal;