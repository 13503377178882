import React, { useEffect } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { Add, Remove, ArrowBack, ArrowForwardIos } from '@mui/icons-material';
import discountIcon from '../assets/discount.png';
import getCurrencySymbol from './getCurrencySymbol';
const PromocodeScreen = ({ open, onClose, promocodeDatas, cartData, handleApplyPromocodeModal, qrsettigns, getContrastingTextColor, hexToRgb, settings, handlePromoSelect }) => {
    console.log(promocodeDatas);
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const modalClassName = open ? 'cartModalContainer open' : 'cartModalContainer';
    const modalContentClassName = open ? 'cartModalContent open' : 'cartModalContent';
    return (
        <Box className={modalClassName}>
            {promocodeDatas && (
                <Box className={modalContentClassName} style={{backgroundColor: qrsettigns?.background_color}}>
                    {open && (
                        <>
                            <Box className='cartModal'>
                                <Box className='cartModalHeader' onClick={onClose}>
                                    <Button variant='text' style={{ color: getContrastingTextColor(qrsettigns?.background_color), minWidth: 'unset' }}><ArrowBack /></Button>
                                    <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>Restaurant coupons for you</Typography>
                                </Box>
                                <Box className='promoModalBody'>
                                    {promocodeDatas.map((promocodeData) => (

                                        promocodeData.promo_min_amount >= cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0).toFixed(2) ? (
                                            <Box key={promocodeData.promo_id} className='promodataBox' style={{backgroundColor: qrsettigns.card_color, color: getContrastingTextColor(qrsettigns.card_color)}}>
                                                <Typography className='promotitle' display="flex" alignItems="center"><img src={discountIcon} alt="Discount" style={{ width: 24, height: 24, marginRight: 8 }} /> {promocodeData.promo_message} </Typography>

                                                <Typography className='promoSubtitle' color='primary' display="flex" alignItems="center">Add item worth {getCurrencySymbol(settings.currency)} {(parseFloat(promocodeData.promo_min_amount) - cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0)).toFixed(2)} more to unlock</Typography>

                                                <Button variant="outlined" color="primary" style={{border: '1px solid #ccc',color: '#ccc'}}className='promoAddButton'>{promocodeData.promocode}</Button>
                                                <Box className="promoBottomBox" style={{backgroundColor:`rgba(${hexToRgb(getContrastingTextColor((qrsettigns['primary_color'] !== '') ?qrsettigns['primary_color']:'#ffffff'))}, 0.3)`}}>
                                                    TAP TO APPLY
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box key={promocodeData.promo_id} className='promodataBox' style={{backgroundColor: qrsettigns.card_color, color: getContrastingTextColor(qrsettigns.card_color)}} onClick={() => handleApplyPromocodeModal(promocodeData)}>
                                                <Typography className='promotitle' display="flex" alignItems="center"><img src={discountIcon} alt="Discount" style={{ width: 24, height: 24, marginRight: 8 }} /> {promocodeData.promo_message} </Typography>
                                                <Button variant="outlined" color="primary" className='promoAddButton'>{promocodeData.promocode}</Button>
                                                <Box className="promoBottomBox" style={{backgroundColor: `rgba(${hexToRgb((qrsettigns['primary_color'] !== '') ?qrsettigns['primary_color']:'#ffffff')}, 0.3)` , color: qrsettigns.primary_color}} onClick={() => handlePromoSelect(promocodeData)}>
                                                    TAP TO APPLY
                                                </Box>
                                            </Box>
                                        )

                                    ))}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PromocodeScreen;