import React, { useEffect } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Add, Remove } from '@mui/icons-material';
const CustomizeVariableProductCart = ({ open, onClose, item, cartItems, handleAddtoCart, handleNewCustomization, qrsettigns, getContrastingTextColor, hexToRgb }) => {
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const modalClassName = open ? 'itemModalContainer open' : 'itemModalContainer';
    const modalContentClassName = open ? 'itemModalContent open' : 'itemModalContent';
    return (
        <Box className={modalClassName}>
            {item && cartItems && (
                <Box className={modalContentClassName} style={{backgroundColor: qrsettigns?.background_color}}>
                    <Box className='closeBtnDiv'>
                        <Button variant='contained' color='secondary' className='closeModalBtn' onClick={onClose}><FontAwesomeIcon icon={faXmark} /></Button>
                    </Box>
                    <Box className='itemModalBody'>
                        {cartItems.map(cartItem => (
                            <Box style={{ display: 'flex', alignItems: 'center', paddingTop: 15, paddingBottom: 15, paddingRight: 15 }}>
                                <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                                    <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{item.product_name}</Typography>
                                    <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>
                                        {JSON.parse(cartItem.product_addons).map(addons => addons.type).join(', ')}
                                    </Typography>
                                </div>
                                <Box className='directAddRemoveItem' style={{backgroundColor:qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color)}}>
                                    <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'remove')}><Remove /></IconButton>
                                    <Typography className='addRemoveQty' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} variant="body1">{cartItem.quantity}</Typography>
                                    <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'add')}><Add /></IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box className='modalFooter'>
                        <div className='newCustomisationBtn' onClick={handleNewCustomization}>
                            <Typography style={{ color: qrsettigns.primary }}>+ Add new customisation</Typography>
                        </div>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default CustomizeVariableProductCart;