import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmationDialog = ({ open, onClose, onConfirm, message, qrsettigns, getContrastingTextColor}) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{'& .MuiDialog-paper': {backgroundColor: qrsettigns?.background_color, color: getContrastingTextColor(qrsettigns?.background_color)}}}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color: getContrastingTextColor(qrsettigns?.background_color)}}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
