import React, { useEffect } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { Add, Remove, ArrowBack } from '@mui/icons-material';
import getCurrencySymbol from './getCurrencySymbol';
const CartScreen = ({ open, onClose, items, cartData, handleAddtoCart, handleVariationQuantityChange, handleNewCustomization, handleHoldOrder, handlePlaceOrder, qrsettigns, getContrastingTextColor, hexToRgb, settings }) => {
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const modalClassName = open ? 'cartModalContainer open' : 'cartModalContainer';
    const modalContentClassName = open ? 'cartModalContent open' : 'cartModalContent';
    return (
        <Box className={modalClassName}>
            {cartData && (
                <Box className={modalContentClassName} style={{backgroundColor: qrsettigns?.background_color}}>
                    {open && (
                        <>
                            <Box className='cartModal'>
                                <Box className='cartModalHeader' onClick={onClose}>
                                    <Button variant='text' style={{ color: getContrastingTextColor(qrsettigns?.background_color), minWidth: 'unset' }}><ArrowBack /></Button>
                                    <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>Your Order</Typography>
                                </Box>
                                <Box className='cartModalBody'>
                                <Box style = {{margin: 15, backgroundColor:qrsettigns.card_color, borderRadius:20, boxShadow:'0 10px 10px rgba(0, 0, 0, 0.1)', color : getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff') }}>
                                    {cartData.filter(cart => cart.hold_status === '2').length !== 0 && (
                                        <Box style={{ padding: 15 }}>
                                            <Typography variant="h6" gutterBottom className='ItemTitle'>Pending for order</Typography>
                                        </Box>
                                    )}
                                    {cartData.filter(cart => cart.hold_status === '2').map((cartItem, index, array) => (
                                        <Box style={{ display: 'flex', alignItems: 'center', padding: 15, borderBottom: index === array.length - 1 ? 'none' : '1px dashed #ddd'}}>
                                            <div className='itemListImageDiv'>
                                                {items.find((item) => item.product_id === cartItem.product_id).product_image ? (
                                                    <img src={`https://testing.smartaspos.com/assets/product_image/${JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_image).file_name}`} alt={items.find((item) => item.product_id === cartItem.product_id).product_name} className='itemListImage' />
                                                ) : (
                                                    <img src={`https://via.placeholder.com/800x400?text=${items.find((item) => item.product_id === cartItem.product_id).product_name.replace(" ", "%20")}`} alt={items.find((item) => item.product_id === cartItem.product_id).product_name} className='itemListImage' />
                                                )}
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                                                <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{items.find((item) => item.product_id === cartItem.product_id).product_name}</Typography>
                                                {JSON.parse(cartItem.product_addons).map(addons => (
                                                    <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>1 x {addons.type}</Typography>
                                                ))}
                                                <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>{getCurrencySymbol(settings.currency)} {parseFloat(cartItem.totle_price).toFixed(2)}</Typography>
                                            </div>
                                            {items.find((item) => item.product_id === cartItem.product_id).product_varients !== '' && JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_varients).filter(Boolean).length !== 0 ? (
                                                JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_varients).filter(Boolean)[0].name !== '' ? (
                                                    cartItem.hold_status === '2' ? (
                                                        <Box>
                                                            <Box className='directAddRemoveItem' style={{backgroundColor:qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color)}}>
                                                                <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'remove')}><Remove /></IconButton>
                                                                <Typography className='addRemoveQty' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} variant="body1">{cartItem.quantity}</Typography>
                                                                <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleVariationQuantityChange(cartData.filter(cart => cart.product_id === items.find((item) => item.product_id === cartItem.product_id).product_id && cart.hold_status === '2'))}><Add /></IconButton>
                                                            </Box>
                                                            <Typography className='customisableText' style={{color:`rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff'))}, 0.5)`}}>customisable</Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            <Box className='holdDirectAddRemoveItem'>
                                                                <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                                <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                                <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                            </Box>
                                                            <Typography className='customisableText' style={{color:`rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff'))}, 0.5)`}}>customisable</Typography>
                                                        </Box>
                                                    )
                                                ) : (
                                                    cartItem.hold_status === '2' ? (
                                                        <Box className='directAddRemoveItem' style={{backgroundColor:qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color)}}>
                                                            <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'remove')}><Remove /></IconButton>
                                                            <Typography className='addRemoveQty' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} variant="body1">{cartItem.quantity}</Typography>
                                                            <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'add')}><Add /></IconButton>
                                                        </Box>
                                                    ) : (
                                                        <Box className='holdDirectAddRemoveItem'>
                                                            <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                            <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                            <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                        </Box>
                                                    )
                                                )) : (
                                                cartItem.hold_status === '2' ? (
                                                    <Box className='directAddRemoveItem' style={{backgroundColor:qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color)}}>
                                                        <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'remove')}><Remove /></IconButton>
                                                        <Typography className='addRemoveQty' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} variant="body1">{cartItem.quantity}</Typography>
                                                        <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'add')}><Add /></IconButton>
                                                    </Box>
                                                ) : (
                                                    <Box className='holdDirectAddRemoveItem'>
                                                        <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                        <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                        <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    ))}
                                    </Box>
                                    <Box style = {{margin: 15, backgroundColor:qrsettigns.card_color, borderRadius:20, boxShadow:'0 10px 10px rgba(0, 0, 0, 0.1)', color : getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff') }}>
                                    {cartData.filter(cart => cart.hold_status === '1').length !== 0 && (
                                        <Box style={{ padding: 15 }}>
                                            <Typography variant="h6" gutterBottom className='ItemTitle'>Ordered Items</Typography>
                                        </Box>
                                    )}
                                    {cartData.filter(cart => cart.hold_status === '1').map((cartItem, index, array) => (
                                        <Box style={{ display: 'flex', alignItems: 'center', padding: 15, borderBottom: index === array.length - 1 ? 'none' : '1px dashed #ddd' }}>
                                            <div className='itemListImageDiv'>
                                                {items.find((item) => item.product_id === cartItem.product_id).product_image ? (
                                                    <img src={`https://testing.smartaspos.com/assets/product_image/${JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_image).file_name}`} alt={items.find((item) => item.product_id === cartItem.product_id).product_name} className='itemListImage' />
                                                ) : (
                                                    <img src={`https://via.placeholder.com/800x400?text=${items.find((item) => item.product_id === cartItem.product_id).product_name.replace(" ", "%20")}`} alt={items.find((item) => item.product_id === cartItem.product_id).product_name} className='itemListImage' />
                                                )}
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                                                <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{items.find((item) => item.product_id === cartItem.product_id).product_name}</Typography>
                                                {JSON.parse(cartItem.product_addons).map(addons => (
                                                    <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>1 x {addons.type}</Typography>
                                                ))}
                                                <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>{getCurrencySymbol(settings.currency)} {parseFloat(cartItem.totle_price).toFixed(2)}</Typography>
                                            </div>
                                            {items.find((item) => item.product_id === cartItem.product_id).product_varients !== '' && JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_varients).filter(Boolean).length !== 0 ? (
                                                JSON.parse(items.find((item) => item.product_id === cartItem.product_id).product_varients).filter(Boolean)[0].name !== '' ? (
                                                    cartItem.hold_status === '2' ? (
                                                        <Box>
                                                            <Box className='directAddRemoveItem' style={{backgroundColor:qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color)}}>
                                                                <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}}  onClick={() => handleAddtoCart(cartItem, 'remove')}><Remove /></IconButton>
                                                                <Typography className='addRemoveQty' style={{color: getContrastingTextColor(qrsettigns.primary_color)}}  variant="body1">{cartItem.quantity}</Typography>
                                                                <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}}  onClick={() => handleVariationQuantityChange(cartData.filter(cart => cart.product_id === items.find((item) => item.product_id === cartItem.product_id).product_id && cart.hold_status === '2'))}><Add /></IconButton>
                                                            </Box>
                                                            <Typography className='customisableText' style={{color:`rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff'))}, 0.5)`}}>customisable</Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            <Box className='holdDirectAddRemoveItem'>
                                                                <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                                <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                                <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                            </Box>
                                                            <Typography className='customisableText' style={{color:`rgba(${hexToRgb(getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff'))}, 0.5)`}}>customisable</Typography>
                                                        </Box>
                                                    )
                                                ) : (
                                                    cartItem.hold_status === '2' ? (
                                                        <Box className='directAddRemoveItem' style={{backgroundColor:qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color)}}>
                                                            <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}}  onClick={() => handleAddtoCart(cartItem, 'remove')}><Remove /></IconButton>
                                                            <Typography className='addRemoveQty' style={{color: getContrastingTextColor(qrsettigns.primary_color)}}  variant="body1">{cartItem.quantity}</Typography>
                                                            <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}}  onClick={() => handleAddtoCart(cartItem, 'add')}><Add /></IconButton>
                                                        </Box>
                                                    ) : (
                                                        <Box className='holdDirectAddRemoveItem'>
                                                            <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                            <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                            <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                        </Box>
                                                    )
                                                )) : (
                                                cartItem.hold_status === '2' ? (
                                                    <Box className='directAddRemoveItem' style={{backgroundColor:qrsettigns.primary_color, color: getContrastingTextColor(qrsettigns.primary_color)}}>
                                                        <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'remove')}><Remove /></IconButton>
                                                        <Typography className='addRemoveQty' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} variant="body1">{cartItem.quantity}</Typography>
                                                        <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns.primary_color)}} onClick={() => handleAddtoCart(cartItem, 'add')}><Add /></IconButton>
                                                    </Box>
                                                ) : (
                                                    <Box className='holdDirectAddRemoveItem'>
                                                        <IconButton className='addRemoveBtn'><Remove /></IconButton>
                                                        <Typography className='addRemoveQty' variant="body1">{cartItem.quantity}</Typography>
                                                        <IconButton className='addRemoveBtn'><Add /></IconButton>
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    ))}
                                    </Box>
                                    <Box style = {{margin: 15, backgroundColor:qrsettigns.card_color, borderRadius:20, boxShadow:'0 10px 10px rgba(0, 0, 0, 0.1)', color : getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff') }}>
                                    <Box>
                                        <Box className='orderData'>
                                            <Typography>SubTotal</Typography>
                                            <Typography>{getCurrencySymbol(settings.currency)} {cartData.reduce((total, cdta) => total + (parseFloat(cdta.totle_price)), 0).toFixed(2)} </Typography>
                                        </Box>
                                        <Box className='orderData'>
                                            <Typography>Service Charge</Typography>
                                            <Typography>{getCurrencySymbol(settings.currency)} 0.00</Typography>
                                        </Box>
                                        <Box className='orderDataTotal'>
                                            <Typography style={{ fontWeight: 'bold' }}>Order Total</Typography>
                                            <Typography style={{ fontWeight: 'bold' }}>{getCurrencySymbol(settings.currency)} {cartData.reduce((total, cdta) => total + parseFloat(cdta.totle_price), 0).toFixed(2)}</Typography>
                                        </Box>
                                    </Box>
                                    </Box>
                                    <Box style={{ display: 'flex', alignItems: 'center', padding: 15, height: 120 }} />
                                </Box>
                            </Box>
                            <Box className='cartModalFooter' style={{backgroundColor: qrsettigns?.background_color}}>
                                <div className='cartBtnDiv'>
                                    <Button className='holdOrder' variant='contained' color='secondary' style={{ marginRight: 'auto' }} onClick={handleHoldOrder}>Hold Order</Button>
                                    <Button className='placeOrder' variant='contained' color='primary' style={{ marginLeft: 'auto' }} onClick={handlePlaceOrder}>Checkout</Button>
                                </div>
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default CartScreen;