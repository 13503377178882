// Modal.js
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, RadioGroup, FormControlLabel, Radio, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Add, Remove } from '@mui/icons-material';
import getCurrencySymbol from './getCurrencySymbol';
const ItemModal = ({ open, onClose, item, handleAddtoCart, qrsettigns, getContrastingTextColor, settings }) => {
    const [selectedVariantsType, setselectedVariantsType] = useState({});
    const [quantity, setQuantity] = useState(1);
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const handleVariantChange = (variant, variantName) => {
        const varientPrice = variant.price[variant.type.findIndex(vatient => vatient === variantName)];
        setselectedVariantsType((prevVariants) => ({
            ...prevVariants,
            [variant.name]: { type: variantName, price: varientPrice },
        }));
    };

    const handleQuantityChange = (amount) => {
        setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
    };

    const calculateTotalPrice = () => {
        const basePrice = parseFloat(item.product_price);
        const variantsTotal = Object.values(selectedVariantsType).reduce((acc, curr) => acc + (parseFloat(curr.price) || 0), 0);
        return (basePrice + variantsTotal) * parseInt(quantity);
    };

    const handleOnClose = () => {
        setselectedVariantsType({});
        setQuantity(1);
        onClose();
    };

    const handleAddtoCartItems = () => {
        handleAddtoCart(item, quantity, selectedVariantsType);
        setselectedVariantsType({});
        setQuantity(1);
    };
    
    const modalClassName = open ? 'itemModalContainer open' : 'itemModalContainer';
    const modalContentClassName = open ? 'itemModalContent open' : 'itemModalContent';
    return (
        <Box className={modalClassName}>
            {item && (
                <Box className={modalContentClassName} style={{backgroundColor: qrsettigns?.background_color}}>
                    <Box className='closeBtnDiv'>
                        <Button variant='contained' color='secondary' className='closeModalBtn' onClick={handleOnClose}><FontAwesomeIcon icon={faXmark} /></Button>
                    </Box>
                    <Box className='itemModalBody'>
                        <Box className='modalConetentInnerBox' style={{backgroundColor: qrsettigns.card_color, color : getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff')}}>
                            <div className='previewItemListImageDiv'>
                                {item.product_image ? (
                                    <img src={`https://testing.smartaspos.com/assets/product_image/${JSON.parse(item.product_image).file_name}`} alt={item.product_name} className='previewItemListImage' />
                                ) : (
                                    item.product_name && (
                                        <img src={`https://via.placeholder.com/800x400?text=${item?.product_name?.replace(" ", "%20")}`} alt={item.product_name} className='previewItemListImage' />
                                    )
                                )}
                                {/* {item.type === 'veg' ? (
                                <i className="vegIcon" size="15" color="#3AB757">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#3AB757" width="15" height="15" viewBox="0 0 20 20" aria-labelledby="icon-svg-title- icon-svg-desc-" role="img" class="sc-rbbb40-0 khXxzt">
                                        <g clip-path="url(#clip0_835:69868)">
                                            <path d="M15 10C15 12.74 12.76 15 10 15C7.24 15 5 12.74 5 10C5 7.26 7.26 5 10 5C12.74 5 15 7.24 15 10ZM20 4V16C20 18.26 18.26 20 16 20H4C1.76 20 0 18.26 0 16V4C0 1.74 1.76 0 4 0H16C18.26 0 20 1.74 20 4V4ZM18.34 4C18.34 2.74 17.26 1.66 16 1.66H4C2.76 1.66 1.66 2.74 1.66 4V16C1.66 17.26 2.76 18.34 4 18.34H16C17.26 18.34 18.34 17.26 18.34 16V4V4Z" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_835:69868">
                                                <rect width="20" height="20" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </i>
                            ) : (
                                <i class="nonVegIcon" size="15" color="#BF4C43">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#BF4C43" width="15" height="15" viewBox="0 0 20 20" aria-labelledby="icon-svg-title- icon-svg-desc-" role="img" class="sc-rbbb40-0 khXxzt">
                                        <g clip-path="url(#clip0_835:69870)">
                                            <path d="M20 4V16C20 18.26 18.26 20 16 20H4C1.76 20 0 18.26 0 16V4C0 1.74 1.76 0 4 0H16C18.26 0 20 1.74 20 4ZM18.34 4C18.34 2.74 17.26 1.66 16 1.66H4C2.76 1.66 1.66 2.74 1.66 4V16C1.66 17.26 2.76 18.34 4 18.34H16C17.26 18.34 18.34 17.26 18.34 16V4Z" />
                                            <path d="M9.99996 3.75L15.8333 14.5833H4.16663L9.99996 3.75Z" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_835:69870">
                                                <rect width="20" height="20" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </i>
                            )} */}
                            </div>
                            <div style={{ flex: 1, paddingLeft: 15, paddingRight: 15 }}>
                                <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{item.product_name}</Typography>
                                <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>{item.product_description}</Typography>
                            </div>
                        </Box>
                        {item.product_varients !== '' && (
                            JSON.parse(item.product_varients).filter(Boolean).map(varients => (
                                varients.name !== '' && (
                                    <Box className='modalConetentInnerBox' style={{backgroundColor: qrsettigns.card_color, color : getContrastingTextColor((qrsettigns?.card_color !== '') ?qrsettigns?.card_color:'#ffffff')}}>
                                        <div style={{ flex: 1, paddingRight: 15, paddingLeft: 15, paddingTop: 15 }}>
                                            <Typography variant="subtitle2" gutterBottom className='ItemTitle'>{varients.name}</Typography>
                                            <Typography variant="caption" display="block" gutterBottom className='ItemPrice'>Required - Select any 1 option</Typography>
                                        </div>
                                        <hr style={{ borderColor: '#fafafa', color: '#fafafa' }} />
                                        <div style={{ flex: 1, paddingRight: 15, paddingLeft: 15 }}>
                                            <RadioGroup value={selectedVariantsType[varients.name]?.type || ''} onChange={(event) => handleVariantChange(varients, event.target.value)} style={{ width: '100%' }}>
                                                {varients.type.map((varienttype, index) => (
                                                    <FormControlLabel
                                                        value={varienttype}
                                                        control={<Radio />}
                                                        label={<div className={`labelContainer ${selectedVariantsType[varients.name]?.type === varienttype ? 'boldLabel' : ''}`}><span>{varienttype}</span> <span>{getCurrencySymbol(settings.currency)} {parseFloat(varients.price[index]).toFixed(2)}</span></div>}
                                                        className="radioLabel"
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </div>
                                    </Box>
                                )
                            )))}
                    </Box>
                    <Box className='modalFooter' style={{backgroundColor: qrsettigns?.background_color}}>
                        <div className='addRemoveItem' style={{borderColor:qrsettigns.primary_color, borderWidth:1, borderStyle:'solid'}}>
                            <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns?.background_color)}} onClick={() => handleQuantityChange(-1)}><Remove /></IconButton>
                            <Typography className='addRemoveQty' variant="body1">{quantity}</Typography>
                            <IconButton className='addRemoveBtn' style={{color: getContrastingTextColor(qrsettigns?.background_color)}} onClick={() => handleQuantityChange(1)}><Add /></IconButton>
                        </div>
                        <div className='addItemBtnDiv'>
                            <Button className='addItemBtn' variant='contained' color='primary' style={{ marginLeft: 'auto' }} onClick={() => handleAddtoCartItems(item, quantity, selectedVariantsType)}>Add item - {getCurrencySymbol(settings.currency)} {calculateTotalPrice().toFixed(2)}</Button>
                        </div>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ItemModal;